/* You can add global styles to this file, and also import other style files */

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '~@angular/cdk/overlay-prebuilt.css';



html,
body {
    height: 100%;
    margin:0px;
}

body {
  background-color: chartreuse;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*markdown editor*/

.ace_editor {
    font-size: 14px;
    color: #111111;
}

.fullscreen .previewHack2 {
    width: 416px;
    border-width: 2px;
    border-color: grey;
    border-style: dashed;
    padding: 4px;
}

.fullscreen{
    top: 60px !important;
    background-color: brown !important;
}

.ag-cell {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.cdk-global-overlay-wrapper {
    /*pointer-events: auto;*/
    display: block;
    position: relative;
    overflow: auto;
    text-align: center;
  }
  
  .cdk-global-overlay-wrapper::before {
    content: '';
    display: inline-block;
    height: 100%;
    white-space: nowrap;
  }
  
  .cdk-overlay-pane {
    display: inline-block;
    position: relative;
    text-align: left;
    white-space: normal;
  }

  /* positions modal at top of screen. Otherwise opens near bottom. not asure why*/
  .moveModal{
    top: 80px;
    left: 400px;
    position: absolute!important;
  }

  .moveMenuOrganizer{
    top: 10px;
    height: 98%;
    max-height: 98%;
    overflow-y: hidden ;
    width: 450px;
    left: 600px;
    position: absolute!important;
  }

  .movePreview{
    top: 80px;
    left: 50px;
    position: absolute!important;
  }

  button.mat-raised-button{
    height: 40px!important;
}

.movePreview .mat-dialog-container {
  padding: 0px !important;
  line-height : normal !important;
}